<template>
  <router-view />
</template>

<style lang="scss">
@import "./assets/styles/main";
</style>

<script>
import AOS from "aos";

export default {
  name: "App",
  components: {},
  mounted() {
    this.initial();
  },
  methods: {
    initial() {
      window.addEventListener("resize", this.appResize);
      AOS.init();
      this.$i18n.locale = this.$route.query.locale;
    },
    appResize() {
      document.documentElement.style.setProperty(
        "--app-height",
        `${window.innerHeight}px`
      );
      document.documentElement.style.setProperty(
        "--app-width",
        `${window.innerWidth}px`
      );
    },
  },
};
</script>
